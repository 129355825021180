import React from "react"

class Kickoff extends React.Component {

  render() {
    return (

      <section className="benifor kickoff mvp pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center">
              Kickoff <span className="bluecolor">Today</span>
            </h2>
            <p className="text-center">
            Unlock the potential of your business model with our custom MVP Software Development services that come with potential guidance and amazing benefits.
            </p>
            <div className="d-lg-flex flex-wrap add-one">
              <div className="square">
                <h3>Get in Touch with us</h3>
                <p className="pharagraph">
                Get connected with our business team with your ideology and project goals. We will be happy to onboard you.
                </p>
              </div>
              <div className="square">
                <h3>Call Consultation</h3>
                <p className="pharagraph">
                Talk to our professional team to define your MVP project vision with clarity. Our expert consultant is ready to assist you.
                </p>
              </div>
              <div className="square">
                <h3>Pick the Cost Quotation</h3>
                <p className="pharagraph">
                Get your business hypothesis valued at the right cost of our MVP Software Development services.
                </p>
              </div>
              <div className="square">
                <h3>Start your project</h3>
                <p className="pharagraph">
                Begin your business endeavors with us as a loyal customer. Let us together transform the ideology into business with MVPs.
                </p>
              </div>
            </div>
            <a href="/contact-us/" className="bluebtn mt-3 mx-auto d-table">Grab A Free Demo</a>
          </div>
        </div>
      </section>

    )
  }
}

export default Kickoff
